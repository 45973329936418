import { useState } from 'react';
import {
  Box,
  TextField,
  InputLabel,
  FormControl,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Stack,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import FormHelperText from '@mui/material/FormHelperText';
import Visibility from 'mdi-material-ui/EyeOutline';
import VisibilityOff from 'mdi-material-ui/EyeOff';
import { signIn } from 'src/redux/features/auth/actions';
import { useDispatch } from 'react-redux';

export default function LoginForm() {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const schema = yup.object().shape({
    email: yup.string().required('This field is required'),
    password: yup.string().required('This field is required'),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const onLogin = (data) => {
    dispatch(signIn(data));
  };
  return (
    <>
      <Stack spacing={3} sx={{ mb: 3 }}>
        <form>
          <FormControl fullWidth sx={{ mb: 3 }}>
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field: { value, onChange, onBlur } }) => (
                <TextField
                  autoFocus
                  value={value}
                  label={'Email'}
                  type="email"
                  onChange={onChange}
                  error={Boolean(errors.email)}
                />
              )}
            />
            {errors.email && <FormHelperText sx={{ color: 'error.main' }}>{errors.email.message}</FormHelperText>}
          </FormControl>

          <Box>
            <FormControl fullWidth>
              <InputLabel error={Boolean(errors.password)}>Password</InputLabel>
              <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, onChange, onBlur } }) => (
                  <OutlinedInput
                    onBlur={onBlur}
                    value={value}
                    label={'Password'}
                    onChange={onChange}
                    error={Boolean(errors.password)}
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                )}
              />
              {errors.password && (
                <FormHelperText sx={{ color: 'error.main' }}>{errors.password.message}</FormHelperText>
              )}
            </FormControl>
          </Box>
          <LoadingButton
            style={{ marginTop: 20 }}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={handleSubmit(onLogin)}
          >
            Login
          </LoadingButton>
        </form>
      </Stack>
    </>
  );
}
