import { Chip } from '@mui/material';
import { toast } from 'react-toastify';

export const constants = {
  authToken: 'token',
  pending: 'PENDING',
  joined: 'JOINED',
};
export const showSuccessTost = (msg) => {
  toast.success(msg, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const showErrorTost = (msg) => {
  toast.error(msg, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const columnCommonCofig = { sortable: false, width: 150 };

export const userTableColumns = [
  { field: 'name', headerName: 'Name', ...columnCommonCofig, flex: 1 },
  { field: 'email', headerName: 'Email', ...columnCommonCofig, flex: 1 },
  {
    field: 'status',
    headerName: 'Status',
    ...columnCommonCofig,
    flex: 1,
    renderCell: ({ row }) => (
      <Chip label={row.status} color={row.status === constants.pending ? 'default' : 'success'} size="small" />
    ),
  },
];
