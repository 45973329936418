import React from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomTextField from '../formFields/textField';
import LoaderButton from '../loaderButton/loaderButton';
import { addUserFormScheme } from '../yup/scheme';

const AddUserForm = ({ onSubmit, loading }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(addUserFormScheme),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <CustomTextField
            type="email"
            placeholder="name@domain.com"
            controller={{
              name: 'email',
              control: control,
              errors: errors.email,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <LoaderButton loading={loading} buttonText={'add'} type="submit" />
        </Grid>
      </Grid>
    </form>
  );
};

export default AddUserForm;
