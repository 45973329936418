import { Container, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { ADD_USER, DELETE_USER, GET_USER } from 'src/apiService/apiDeclaration';
import ReadExcel from 'src/components/excel/readExcel';
import AddUserForm from 'src/components/form/addUserForm';
import { IMAGE_LIBRARY } from 'src/components/imageLibrary/imageLibrary';
import MenuPopover from 'src/components/menu/menu';
import PageHeader from 'src/components/pageHeader/PageHeader';
import TabTitle from 'src/components/tabTitle/tabTitle';
import CustomTable from 'src/components/table/table';
import { showErrorTost, showSuccessTost, userTableColumns } from 'src/constants';
import { useModal } from 'src/hooks/useModal';

export default function UsersPage() {
  const { setDialogData, handleModalClose } = useModal();
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 5 });
  const [users, setUsers] = useState({ rowCount: 0, rows: [] });
  const updatedUsersColumn = [...userTableColumns];
  const [loading, setLoading] = useState(false);
  const getUsers = async () => {
    setLoading(true);
    try {
      const params = { page: paginationModel.page + 1, pageSize: paginationModel.pageSize };
      const queryString = Object.keys(params)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');

      const {
        data: { rows, rowCount },
      } = await GET_USER(queryString);
      setUsers({ rows, rowCount });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const addUser = async (users) => {
    try {
      const {
        data: { existingEmails },
      } = await ADD_USER({ users });
      if (existingEmails.length) {
        showErrorTost(`Below email/s already exists${'\n'}${JSON.stringify(existingEmails)}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteUser = async (id) => {
    try {
      const resp = await DELETE_USER(id);
      showSuccessTost(resp.message);
      handleModalClose();
      getUsers();
    } catch (error) {
      showErrorTost(error.message || 'Error delete user');
      console.log(error);
    }
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, [paginationModel]);

  updatedUsersColumn.push({
    field: 'action',
    headerName: 'Action',
    sortable: false,
    disableColumnMenu: true,
    width: 80,
    renderCell: ({ row }) => {
      return (
        <MenuPopover
          menuArray={[
            {
              icon: IMAGE_LIBRARY.delete,
              name: 'Delete User',
              action: () => {
                setDialogData({
                  showModal: true,
                  description: 'Are you sure you want to permanently delete this user?',
                  onConfirm: () => deleteUser(row._id),
                });
              },
            },
          ]}
        />
      );
    },
  });

  return (
    <Container>
      <Grid container spacing={2}>
        <TabTitle title={'Users'} />
        <Grid item xs={12}>
          <PageHeader pageTitle={'Users'}>
            <MenuPopover
              isButton
              buttonText={'Add new user'}
              menuArray={[
                {
                  name: 'Add email',
                  icon: 'https://static.vecteezy.com/system/resources/previews/019/896/028/original/add-new-user-icon-in-black-colors-profile-avatar-with-plus-symbol-png.png',
                  action: () => {
                    setDialogData({
                      showModal: true,
                      title: 'Enter user email',
                      child: (
                        <AddUserForm
                          onSubmit={async (data) => {
                            await addUser([data]);
                            handleModalClose();
                            getUsers();
                          }}
                        />
                      ),
                    });
                  },
                },
                {
                  name: 'Upload file',
                  icon: 'https://freepngimg.com/download/icon/app/9764-upload-csv.png',
                  action: () => {
                    setDialogData({
                      showModal: true,
                      description: 'Upload File',
                      child: (
                        <ReadExcel
                          onSubmit={async (data) => {
                            await addUser(data);
                            handleModalClose();
                            getUsers();
                          }}
                        />
                      ),
                    });
                  },
                },
              ]}
            />
          </PageHeader>
        </Grid>
        <Grid item xs={12}>
          <CustomTable
            loading={loading}
            isServerSidePagination
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            columns={updatedUsersColumn}
            rows={users.rows}
            rowCount={users.rowCount}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
