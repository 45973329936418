import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import DashboardAppPage from '../pages/DashboardAppPage';
import UsersPage from 'src/pages/UsersPage';

export default function HomeRouter() {
  const routes = useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/" />, index: true },
        { path: 'dashboard', element: <DashboardAppPage /> },
        { path: 'users', element: <UsersPage /> },
        { path: '*', element: <Navigate to="/dashboard" /> },
      ],
    },
  ]);

  return routes;
}
