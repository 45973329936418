import { Button, Grid, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const LoaderButton = ({ loading, onClick, buttonText, ...props }) => {
  return (
    <Button onClick={onClick} variant="contained" size="small" disabled={loading} {...props}>
      <Grid
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: 1,
          alignItems: 'center',
        }}
      >
        {loading && <CircularProgress color="primary" size={20} />}
        <Typography>{buttonText}</Typography>
      </Grid>
    </Button>
  );
};

export default LoaderButton;
