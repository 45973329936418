import { useRoutes } from 'react-router-dom';
import LoginPage from '../pages/LoginPage';

export default function AuthRouter() {
  const routes = useRoutes([
    {
      path: '/',
      element: <LoginPage />,
    },
    {
      path: '*',
      element: <LoginPage />,
    },
  ]);

  return routes;
}
