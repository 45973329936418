import { FormHelperText, TextField, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';

const CustomTextField = ({ controller, label, type = 'text', required = true, ...props }) => {
  return (
    <div>
      {label && (
        <>
          <Typography variant="caption">{label}</Typography>
          <br />
        </>
      )}

      {controller ? (
        <>
          <Controller
            control={controller.control}
            name={controller.name}
            rules={{ required }}
            render={({ field: { onChange, value, onBlur } }) => (
              <TextField
                fullWidth
                type={type}
                size="small"
                onChange={onChange}
                error={Boolean(controller.errors)}
                value={value ? value : ''}
                onBlur={onBlur}
                {...props}
              />
            )}
          />
          {controller.errors && (
            <FormHelperText sx={{ color: 'error.main' }}>{controller.errors.message}</FormHelperText>
          )}
        </>
      ) : (
        <TextField type={type} fullWidth size="small" {...props} />
      )}
    </div>
  );
};

export default CustomTextField;
